// @ts-nocheck
/* eslint-disable react/forbid-component-props */
/* eslint-disable react/prop-types */
import { Typography, Box, IconButton } from '@mui/material';
import React, { useCallback, useState } from 'react';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import CustomDraggableModal from '../../utils/material-ui_common/CustomDraggableDialog/CustomDraggableDailog';
import CommentBox from './CommentBox';
import { ReactComponent as CommentButtonIcon } from '../../assets/images/CommentIcon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/DownloadIcon.svg';
import { ReactComponent as ExpandWindowIcon } from '../../assets/images/ExpandWindowIcon.svg';
import { ReactComponent as CollapseWindowIcon } from '../../assets/images/CollapseWindowIcon.svg';
import utils from '../../utils/utils';
import AddResultToModelMetaDataModal from './AddResultToModelMetaDataModal';

/**
 * @function OperationResultsViwerImageContainer
 * @description A Container to view operation results
 * @param {any} url
 * @param {number} commentId    - A numerical value which give the id of comment
 * @param {string} imageHeader  - A string value which gives the information about image
 * @param {string} commentTableName - A string value which give the name of commentTable
 * @param {boolean} showCommentBox  - A boolean to which tells to show commentBox or not
 * @param {string} key -  A string value
 * @param {string} moduleName  - A string value which gives the name of module
 * @param {boolean} isConsole  - A boolean value
 * @param {boolean} dialogOpen - A boolean value to check the condition to open dialog box
 * @param {function} handleDialogOpen - A function to handle dialog box opening
 * @param {object} imgData   -  An object to store data of image
 * @param {function} setImageData -  A function to set image data
 * @param {function} handleDialogClose - A function to handle closing of dialog box
 * @param {string} permission  -  A string value to check the permission
 * @returns {JSX.Element} OperationResultsViwerImageContainer UI
 * @example <OperationResultsViwerImageContainer />
 */
const OperationResultsViwerImageContainer = ({
  url,
  commentId,
  imageHeader,
  // commentTableName,
  showCommentBox = true,
  key,
  moduleName,
  isConsole = false,
  dialogOpen,
  handleDialogOpen,
  imgData,
  setImageData,
  handleDialogClose,
  permission = 'Manage',
  addResultToModelMetaData,
  modelMetaDataSchema,
  activeProject,
  b64,
}) => {
  const imageElem = React.useRef(null);
  const [openGraphComment, setOpenGraphComment] = useState(false);

  const [openAddToModelMetaData, setOpenAddToModelMetaData] = useState(false);

  const handleAddToModelMetaDataModalClose = useCallback(() => {
    setOpenAddToModelMetaData(false);
  }, [setOpenAddToModelMetaData]);
  // with this appraoch we are facing cors issue and backend team is still not able to solve it.
  // so adding different implementation. Which is based on canvas.
  // useEffect(() => {
  //   axios
  //     .get(url, {
  //       responseType: 'blob',
  //     })
  //     .then((res) => {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         if (reader.result) {
  //           const b64 = reader.result;
  //           if (b64 && b64.includes('data:image/svg+xml;base64')) {
  //             setImageBase64(b64);
  //           } else {
  //             setImageBase64(b64.substring(22, b64.length));
  //           }
  //         }
  //       };
  //       reader.readAsDataURL(res.data);
  //     });
  // }, [url]);

  // this approach needs to be tested for SVG
  // @TO-DO

  const showAddToModelMetaDataOption = React.useMemo(
    () =>
      addResultToModelMetaData &&
      activeProject?.system_generated_flag &&
      activeProject?.system_name === 'Vault',
    [addResultToModelMetaData, activeProject]
  );
  /**
   * @function handleCommentBoxOpen
   * @description To change the state of openGraphComment from null to true
   * @returns {void}
   * @example handleCommentBoxOpen()
   */
  const handleCommentBoxOpen = () => {
    setOpenGraphComment(true);
  };
  /**
   * @function handleCommentBoxClose
   * @description To change the state of openGraphComment from null to false
   * @returns {void}
   * @example handleCommentBoxClose()
   */
  const handleCommentBoxClose = useCallback(() => {
    setOpenGraphComment(false);
  }, [setOpenGraphComment]);

  const handleOnClick = useCallback(() => {
    handleDialogOpen();
    setImageData({ ...imgData, type: 'image' });
  }, [handleDialogOpen, setImageData]);
  return (
    <Box display="flex" flexDirection="column" height="inherit">
      <Box display="flex" flexDirection="column">
        <Box
          mb={0}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box mt={1} ml={1}>
            <Typography variant="h4" style={{ textTransform: 'capitalize' }}>
              {imageHeader}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" flexGrow={1}>
            <IconButton
              color="primary"
              onClick={() => {
                utils.downloadImageFile(b64);
              }}
            >
              <DownloadIcon title="Download" />
            </IconButton>
            {showCommentBox && (
              <IconButton
                title={
                  permission !== 'Manage'
                    ? 'You are not authorized to access this!'
                    : ''
                }
                onClick={() => handleCommentBoxOpen()}
                disabled={permission !== 'Manage'}
              >
                <CommentButtonIcon title="Add Comments" />
              </IconButton>
            )}
            {showAddToModelMetaDataOption ? (
              <IconButton
                color="primary"
                onClick={() => {
                  setOpenAddToModelMetaData(true);
                }}
                disabled={permission !== 'Manage'}
                title={
                  permission !== 'Manage'
                    ? 'You are not authorized to access this!'
                    : 'Add to Vault.'
                }
              >
                <ModelTrainingIcon />
              </IconButton>
            ) : null}
            {!dialogOpen ? (
              <IconButton>
                <ExpandWindowIcon
                  title="Expanded View"
                  onClick={handleOnClick}
                  size="small"
                />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  handleDialogClose();
                }}
                size="small"
              >
                <CollapseWindowIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>

      <Box height="inherit">
        <Box width="100%" height="100%" textAlign="center">
          <img
            ref={imageElem}
            id="operation_result_image"
            key={key}
            src={url}
            alt="Operation result"
            crossOrigin="anonymous"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              width: 'auto',
              height: 'auto',
            }}
          />
        </Box>
      </Box>
      <CustomDraggableModal
        show={openGraphComment}
        header={<span>Comments / Insights</span>}
        onHide={handleCommentBoxClose}
      >
        <CommentBox
          handleCommentBoxClose={handleCommentBoxClose}
          b64={b64}
          openDialog={openGraphComment}
          id={commentId}
          // tableName={commentTableName}
          type="chart"
          moduleName={moduleName}
          isConsole={isConsole}
        />
      </CustomDraggableModal>
      {showAddToModelMetaDataOption ? (
        <AddResultToModelMetaDataModal
          open={openAddToModelMetaData}
          onClose={handleAddToModelMetaDataModalClose}
          addResultToModelMetaData={addResultToModelMetaData}
          modelMetaDataSchema={modelMetaDataSchema}
          activeProject={activeProject}
          resultContents={b64}
          resultType="chart"
        />
      ) : null}
    </Box>
  );
};

export default OperationResultsViwerImageContainer;
