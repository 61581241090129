/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import {
  Box,
  Grid,
  Typography,
  Tabs,
  Tab,
  Dialog,
  Divider,
  TextField,
  Slide,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { utils as UTILS } from 'solytics-frontend';
import React, { useCallback } from 'react';
import IframeContainer from '../AllCustomComponents/Iframe/IframeContainer';
import TableWithComment from './Table/TableWithComment';
import { ReactComponent as ActiveTabIndicatorIcon } from '../../assets/images/ActiveTabIndicatorIcon.svg';
import { ReactComponent as InactiveTabIndicatorIcon } from '../../assets/images/InactiveTabIndicatorIcon.svg';
import DialogTransition from './Transitions/DialogTransition';
import MODULE_SUB_MODULE_OPERATIONS_TREE from '../../const/ModuleSubModuleOperationsTree';

const PREFIX = 'GridTasksTableAndCharts';

const classes = {
  root: `${PREFIX}-root`,
  graphBox: `${PREFIX}-graphBox`,
  tabPanelBox: `${PREFIX}-tabPanelBox`,
  tableBox: `${PREFIX}-tableBox`,
  input: `${PREFIX}-input`,
};

const itemAlignment = 'flex-start';
const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.root}`]: {
    background: theme.palette.primary.contrastText,
    width: '100%',
  },
  [`& .${classes.graphBox}`]: {
    minWidth: 500,
    marginRight: theme.spacing(1.5),
    overflowY: 'auto',
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'row',
    height: 325,
  },
  [`& .${classes.tableBox}`]: {
    minWidth: 500,
    marginRight: theme.spacing(1.5),
    overflowY: 'auto',
    paddingRight: 10,
    height: 280,
    display: 'flex',
    flexDirection: 'row',
  },
  [`& .${classes.tabPanelBox}`]: {
    display: 'flex',
    alignItems: 'center',
    overflowY: 'auto',
    padding: '20px 0 20px 0',
  },
  [`& .${classes.input}`]: {
    backgroundColor: theme.palette.other.grey1,
    background: theme.palette.other.grey1,
  },
  '& .MuiTabs-root': {
    display: 'flex',
    borderBottom: 0,
    justifyContent: 'center',
    alignItems: itemAlignment,

    '& .Mui-selected': {
      color: theme.palette.other.lightBrandBlueLogin,
    },
    '& .MuiTabs-indicator': {
      background: 'none',
    },
    '& .MuiTab-root': {
      display: 'flex',
      alignItems: itemAlignment,
      padding: 1,
      minHeight: '36px',
      maxHeight: '36px',
      flexDirection: 'column',
    },
    '& .MuiButtonBase-root': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: itemAlignment,
    },
  },

  '& .MuiTabs-scroller': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    marginRight: '40px !important',
  },
  '& .MuiTableCell-head': {
    padding: '2px 2px 2px 2px !important',
    fontSize: '10px !important',
  },
  '& .MuiTableCell-sizeSmall': {
    padding: '2px 2px 2px 2px !important',
    fontSize: '12px !important',
  },
  '& .MuiTablePagination-toolbar': {
    background: 'white',
  },
}));

/**
 * @function ColoredCell
 * @description For Colored Cell
 * @param {object} data  - A object which store the information of colored cell
 * @returns {JSX.Element}
 * @example <ColoredCell data={data}/>
 */
const ColoredCell = (data) => {
  const { cell } = data;
  const isColor = cell?.value?.toString().split('').includes('#');
  return (
    <>
      {isColor && data ? (
        <Box textAlign="center" bgcolor={cell?.value} padding={1} />
      ) : (
        <Box>
          {Array.isArray(cell?.value)
            ? UTILS.getFormattedNumber(cell?.value.length)
            : UTILS.getFormattedNumber(cell?.value)}
        </Box>
      )}
    </>
  );
};

/**
 * @function TabPanel
 * @description Tabpanel component to be used in grid operations results viewer
 * @param  {object} props -active operation name
 * @returns {JSX.Element} tabpanel UI
 * @example <TabPanel value={value}/>
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

/**
 * @function GridTasksTableAndCharts
 * @description common component function of table and charts tasks of grid
 * @param  {object} operationName -active operation name
 * @param {object} activeDataset - currently active dataset
 * @param {object} gridTaskResults - results and response of tasks of grid
 * @param {object} permission - permission of user
 * @param {object} fetchTableDataResult - result of table data
 * @param {object} onDownloadCSV - function to download csv
 * @returns {JSX} grid task table and charts results UI
 * @example <GridTasksTableAndCharts operationName={operationName}/>
 */

const GridTasksTableAndCharts = ({
  operationName,
  // activeDataset,
  gridTaskResults,
  permission,
  fetchTableDataResult,
  onDownloadCSV,
}) => {
  const {
    is_chart,
    is_table,
    charts,
    tables,
    table_id: tableId,
    pds,
    dfcs,
    // eslint-disable-next-line no-unsafe-optional-chaining
  } = gridTaskResults?.results;
  const [value, setValue] = React.useState(0);

  /**
   * @function handleChange
   * @description function to handle change of tab
   * @param {object} event - event object
   * @param {number} newValue - new value of tab
   * @returns {void} - returns nothing
   */

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [graphData, setGraphData] = React.useState([]);

  /**
   * @function handleDialogOpen
   * @description function to handle dialog open
   * @returns {void} - returns nothing
   */

  const handleDialogOpen = useCallback(() => {
    setDialogOpen(true);
  }, [setDialogOpen]);

  /**
   * @function handleDialogClose
   * @description function to handle dialog close
   * @returns {void} - returns nothing
   */

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  React.useEffect(
    (e) => {
      if (gridTaskResults?.results) {
        handleChange(e, 0);
      }
    },
    [gridTaskResults?.results, operationName]
  );

  const swapHeaderName = (data) => {
    const tableArray = data?.data?.map((item) => ({
      Bucket: item.Bucket,
      'row count': item.count,
      'color code': item.color_code,
    }));
    const newTableArray = { data: tableArray };
    return newTableArray;
  };

  return (
    <StyledBox
      sx={{
        flexGrow: 1,
        bgcolor: 'other.white',
        display: 'flex',
        borderRadius: 1,
        minHeight: '280px',
        flexDirection: 'column',
      }}
    >
      <Box display="flex" flexDirection="row">
        {is_chart || is_table ? (
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Vertical tabs example"
            sx={{ width: 190 }}
          >
            {gridTaskResults?.results.is_chart && (
              <Tab
                icon={
                  value === 0 ? (
                    <ActiveTabIndicatorIcon />
                  ) : (
                    <InactiveTabIndicatorIcon />
                  )
                }
                iconPosition="start"
                label="Charts"
              />
            )}

            {is_table && (
              <Tab
                icon={
                  (!is_chart && value === 0) || (is_chart && value === 1) ? (
                    <ActiveTabIndicatorIcon />
                  ) : (
                    <InactiveTabIndicatorIcon />
                  )
                }
                iconPosition="start"
                label="Tables"
              />
            )}

            {gridTaskResults?.parameters && (
              <Tab
                icon={
                  (is_table && is_chart && value === 2) ||
                  (value === 1 && is_table && !is_chart) ||
                  (value === 1 && is_chart && !is_table) ? (
                    <ActiveTabIndicatorIcon />
                  ) : (
                    <InactiveTabIndicatorIcon />
                  )
                }
                iconPosition="start"
                label="Parameters"
              />
            )}
            {operationName === 'Scores' && gridTaskResults?.results && (
              <Tab
                icon={
                  value === 2 ? (
                    <ActiveTabIndicatorIcon />
                  ) : (
                    <InactiveTabIndicatorIcon />
                  )
                }
                iconPosition="start"
                label="Others"
              />
            )}
          </Tabs>
        ) : gridTaskResults?.parameters &&
          Object.keys(gridTaskResults?.parameters).length ? (
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ width: 250 }}
          >
            {gridTaskResults?.parameters && (
              <Tab
                icon={
                  value === 0 ? (
                    <ActiveTabIndicatorIcon />
                  ) : (
                    <InactiveTabIndicatorIcon />
                  )
                }
                iconPosition="start"
                label="Parameters"
              />
            )}
            {operationName === 'Scores' && gridTaskResults?.results && (
              <Tab
                icon={
                  value === 1 ? (
                    <ActiveTabIndicatorIcon />
                  ) : (
                    <InactiveTabIndicatorIcon />
                  )
                }
                iconPosition="start"
                label="Others"
              />
            )}
          </Tabs>
        ) : null}
        <Box className={classes.tabPanelBox} sx={{ width: '100%' }}>
          <TabPanel value={value} index={0}>
            <Slide direction="left" in={[0, 1, 2].includes(value)}>
              <Box ml={3} display="flex" flexDirection="row">
                {is_chart &&
                  charts?.map((data) => (
                    <Box key={data.id} className={classes.graphBox}>
                      <Box height={270} width="100%">
                        <IframeContainer
                          codeSnippet={data.code}
                          zoomResultImg={1}
                          key={data.id}
                          url={data.url}
                          b64={data?.b64}
                          msg={data.msg}
                          status={data.status}
                          commentid={data.id}
                          chartHeader={data?.chart_title}
                          // commenttableName={activeDataset.Name}
                          moduleName={MODULE_SUB_MODULE_OPERATIONS_TREE.DM.key}
                          graphData={data}
                          dialogOpen={dialogOpen}
                          handleDialogOpen={handleDialogOpen}
                          setGraphData={setGraphData}
                          handleDialogClose={handleDialogClose}
                          permission={permission}
                        />
                      </Box>
                      {charts?.length > 1 && charts?.length !== data.id + 1 ? (
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            marginLeft: '24px',
                          }}
                        />
                      ) : null}
                    </Box>
                  ))}
              </Box>
            </Slide>
          </TabPanel>
          <TabPanel value={value} index={is_chart ? 1 : 0}>
            <Slide direction="left" in={[0, 1, 2].includes(value)}>
              <Box ml={3} display="flex" flexDirection="row">
                {is_table &&
                  tables?.map((data, index) => (
                    <Box key={tableId[index]} className={classes.tableBox}>
                      <Box padding={1} width="100%" height={270}>
                        <TableWithComment
                          type="outputTable"
                          tableData={
                            operationName !== 'Scores'
                              ? data
                              : swapHeaderName(data)
                          }
                          opName={
                            operationName
                              ? `GRID_${operationName.toUpperCase()}`
                              : ''
                          }
                          commentid={tableId[index]}
                          // commenttableName={
                          //   activeDataset?.Name ? activeDataset?.Name : ''
                          // }
                          moduleName={MODULE_SUB_MODULE_OPERATIONS_TREE.DM.key}
                          isAllOptionDisabled={true}
                          rowsPerPageOptions={[4]}
                          isGridTaskTable={true}
                          customCell={ColoredCell}
                          initialPageSize={5}
                          fetchTableData={fetchTableDataResult}
                          permission={permission}
                          isPaginated={data?.is_paginated}
                          downloadTableData={onDownloadCSV}
                        />
                      </Box>
                      {tables?.length !== index + 1 ? (
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            marginLeft: '24px',
                          }}
                        />
                      ) : null}
                    </Box>
                  ))}
              </Box>
            </Slide>
          </TabPanel>
          <TabPanel
            value={value}
            index={is_chart && is_table ? 2 : is_chart || is_table ? 1 : 0}
          >
            <Slide direction="left" in={[0, 1, 2].includes(value)}>
              <Box
                ml={3}
                display="flex"
                flexDirection="row"
                className={classes.tableBox}
                pt={5}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  height="auto"
                  padding={2}
                >
                  <Grid container direction="row">
                    {Object?.entries(gridTaskResults?.parameters)
                      ?.filter(
                        ([key, ivalue]) =>
                          !['table_name', 'user', 'internal_name'].includes(
                            key
                          ) && typeof ivalue !== 'object'
                      )
                      ?.map(([key, ivalue]) => (
                        <Grid container spacing={5} key={ivalue.id}>
                          <Grid item xs={4} sx={{ margin: 1 }}>
                            <Typography
                              key={ivalue.id}
                              sx={{ textTransform: 'capitalize' }}
                              color="other.grey2"
                              variant="body2"
                            >
                              {key !== 'Display Name'
                                ? `${key.split('_').join(' ')} :`
                                : 'Table Name :'}
                            </Typography>
                          </Grid>
                          <Grid item xs={7} sx={{ margin: 1 }}>
                            <Typography key={ivalue.id} variant="body1">
                              {ivalue}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Box>
            </Slide>
          </TabPanel>
          {operationName === 'Scores' && gridTaskResults?.results && (
            <TabPanel value={value} index={pds ? 2 : 1}>
              <Slide direction="left" in={value === 2 || value === 1}>
                <Box width={150}>
                  <TextField
                    label={pds ? 'PDS Score' : 'DFCS Score'}
                    disabled={true}
                    value={pds || dfcs}
                    variant="filled"
                    inputProps={{
                      className: classes.input,
                    }}
                  />
                </Box>
              </Slide>
            </TabPanel>
          )}
        </Box>
        <Dialog
          fullWidth
          aria-labelledby="customized-dialog-title"
          maxWidth="xl"
          open={dialogOpen}
          TransitionComponent={DialogTransition}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <Box height={650} width="100%" justifyContent="center">
                {graphData ? (
                  <IframeContainer
                    codeSnippet={graphData.code}
                    key={0}
                    url={graphData.url}
                    b64={graphData?.b64}
                    msg={graphData.msg}
                    status={graphData.status}
                    commentid={graphData.id}
                    // commenttableName={activeDataset.Name}
                    chartHeader={graphData?.chart_title}
                    graphData={graphData}
                    dialogOpen={dialogOpen}
                    handleDialogOpen={handleDialogOpen}
                    setGraphData={setGraphData}
                    handleDialogClose={handleDialogClose}
                    permission={permission}
                  />
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
      <Box display="flex" flexDirection="row">
        {gridTaskResults?.results?.completion_message && (
          <Grid container spacing={4}>
            <Grid item xs={1} sx={{ margin: 3 }}>
              <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
                Message:
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ margin: 3 }}>
              <Typography variant="body2">
                {gridTaskResults.results?.completion_message}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Box>
    </StyledBox>
  );
};

GridTasksTableAndCharts.defaultProps = {
  fetchTableDataResult: () => {},
};

GridTasksTableAndCharts.propTypes = {
  operationName: PropTypes.string.isRequired,
  // activeDataset: PropTypes.oneOfType([PropTypes.object]).isRequired,
  gridTaskResults: PropTypes.oneOfType([PropTypes.object]).isRequired,
  permission: PropTypes.string.isRequired,
  fetchTableDataResult: PropTypes.func,
  onDownloadCSV: PropTypes.func.isRequired,
};

export default GridTasksTableAndCharts;
