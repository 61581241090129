/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Grid, Box, Dialog, Fab, Zoom, Slide, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import ModelComparisonAndResultsFilter from '../ModelEstimation/ModelComparisonAndResultsFilter';
import DialogTransition from './Transitions/DialogTransition';
import TableWithComment from './Table/TableWithComment';
import IframeContainer from '../AllCustomComponents/Iframe/IframeContainer';
import OperationResultsViwerImageContainer from './OperationResultsViewerImageContainer';
import OperationResultsViewerDatasetTable from './OperationResultsViewerDatasetTable';

const significanceMarks =
  'Significance level codes: 0 ‘***’ 0.001 ‘**’ 0.01 ‘*’ 0.05 ‘.’ 0.1 ‘ ’ 1';

const PREFIX = 'OperationResultsViewer';

const classes = {
  root: `${PREFIX}-root`,
  graphCard: `${PREFIX}-graphCard`,
  singleGraphCard: `${PREFIX}-singleGraphCard`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },
  [`& .${classes.graphCard}`]: {
    height: theme.spacing(60),
    position: 'relative',
    boxShadow: 'none !important',
  },
  [`& .${classes.singleGraphCard}`]: {
    height: theme.spacing(80),
    position: 'relative',
    boxShadow: 'none !important',
  },
}));

/**
 * @function OperationResultsViewer
 * @description to view result of operation
 *  @param {object} taskResults - object containing result of operation
 * @param {object} activeDataset - currently active dataset
 * @param {string} operationName - name of operation
 * @param {boolean} customCell - boolean to check if custom cell is required
 * @param {string} permission - A string value
 * @param {object} parameters - parameters of analyse operation
 * @param {function} onAddChartClick - function to be called on click of add chart
 * @param {function} onAddTableClick - function to be called on click of add table
 * @param {object} activeProject - An object which store active projects in DatasetTable
 * @param {array} taskResultDatasets - An array of task result datasets
 * @param {array} paginatedDatasetRowData - An array of paginated dataset row data
 * @param {number} totalDatasetRows - total number of dataset rows
 * @param {function} fetchDatasetRowsWithPagination - function to fetch dataset rows with pagination
 * @param {number} datasetRowsPageCount - number of dataset rows page count
 * @param {boolean} isLoading - boolean to check if data is loading
 * @param {object} datasetList   - An object which store all the datasets
 * @param {function} downloadTableData -function to download table data
 * @param {string} moduleName - module name
 * @param {string} subModuleName - sub module name
 * @param {function} fetchTableDataResult - function to fetch table data result
 * @param {array} columnsToExcludeFromNumberFormatting - array of columns for with number formatting (comma separation) should not be applied.
 * @returns {JSX.Element}
 */

const OperationResultsViewer = ({
  taskResults = {},
  // activeDataset = {},
  operationName = '',
  customCell = null,
  permission = '',
  parameters = {},
  fetchTableDataResult,
  downloadTableData,
  onAddChartClick,
  onAddTableClick,
  activeProject = {},
  taskResultDatasets = [],
  paginatedDatasetRowData = [],
  totalDatasetRows = 0,
  fetchDatasetRowsWithPagination = () => {},
  datasetRowsPageCount = 0,
  isLoading = false,
  datasetList = [],
  moduleName,
  subModuleName,
  columnsToExcludeFromNumberFormatting = [],
  fetchAllDatasets,
  markDatasetAsReadyToUse,
  addResultToModelMetaData,
  fetchModelMetaDataSchema,
  enableComments = true,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [_taskResults, _setTaskResults] = useState(taskResults);
  const [showResultsOnComparison, setShowResultsOnComparison] = useState(true);
  const [modelComparisonView, setModelComparisonView] = useState(false);
  const [modelMetaDataSchema, setModelMetaDataSchema] = useState(null);

  /**
   * @function handleDialogOpen
   * @description function to open dialog
   * @returns {void}
   */

  const handleDialogOpen = useCallback(() => {
    setDialogOpen(true);
  }, [setDialogOpen]);

  /**
   * @function handleDialogClose
   * @description function to close dialog
   * @returns {void}
   */

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);
  const [activeView, setActiveView] = useState(null);
  const [switchReadyToUse, setSwitchReadyToUse] = useState(false);
  useEffect(() => {
    if (modelComparisonView === false) {
      _setTaskResults(taskResults);
    }
  }, [taskResults]);
  useEffect(() => {
    if (!switchReadyToUse) {
      if (taskResults?.is_chart) {
        setActiveView('graph');
      } else if (taskResults?.is_image) {
        setActiveView('image');
      } else if (taskResults?.is_grid) {
        setActiveView('dataset');
      } else if (taskResults?.is_table) {
        setActiveView('table');
      } else {
        setActiveView('');
      }
    }
  }, [taskResults]);

  React.useEffect(() => {
    if (
      // eslint-disable-next-line no-constant-condition
      fetchModelMetaDataSchema &&
      activeProject?.project_id &&
      activeProject?.system_generated_flag &&
      activeProject?.system_name === 'Vault'
    ) {
      fetchModelMetaDataSchema({
        project_id: activeProject?.project_id,
        type: activeProject?.type,
      }).then(
        (resp) => {
          setModelMetaDataSchema(resp?.data);
        },
        () => {
          setModelMetaDataSchema(null);
        }
      );
    }
  }, []);

  return (
    <StyledGrid container className={classes.root}>
      <Slide direction="left" in={true} timeout={900}>
        <Box width="100%">
          {operationName === 'ModelInitiation' ||
          operationName === 'ModelInitiationTS' ? (
            <ModelComparisonAndResultsFilter
              taskResults={_taskResults}
              setTaskResults={_setTaskResults}
              parameters={parameters}
              activeView={activeView}
              operationName={operationName}
              setShowResultsOnComparison={setShowResultsOnComparison}
              modelComparisonView={modelComparisonView}
              setModelComparisonView={setModelComparisonView}
            />
          ) : null}
        </Box>
      </Slide>
      {showResultsOnComparison && activeView && (
        <>
          {_taskResults?.is_chart ||
          _taskResults?.is_table ||
          _taskResults?.is_image ||
          (_taskResults?.is_grid && taskResultDatasets?.length) ? (
            <Slide direction="left" in={true} timeout={900}>
              <Fab
                variant="extended"
                size="small"
                disableRipple={true}
                disableFocusRipple={true}
                sx={{
                  padding: 0,
                  ':hover': { backgroundColor: 'secondary.main' },
                  position: 'fixed',
                  bottom: '30px',
                  right: ' 30px',
                  zIndex: 1000,
                }}
                color="secondary"
              >
                {_taskResults?.tables?.length && _taskResults?.is_table ? (
                  <Fab
                    variant="extended"
                    size="small"
                    sx={{
                      boxShadow: activeView === 'table' ? 1 : 0,
                    }}
                    color={activeView === 'table' ? 'primary' : 'secondary'}
                    aria-label="add"
                    onClick={() => {
                      setActiveView('table');
                    }}
                  >
                    Tables
                  </Fab>
                ) : null}
                {_taskResults?.charts?.length && _taskResults?.is_chart ? (
                  <Fab
                    variant="extended"
                    size="small"
                    sx={{
                      boxShadow: activeView === 'graph' ? 1 : 0,
                    }}
                    color={activeView === 'graph' ? 'primary' : 'secondary'}
                    aria-label="add"
                    onClick={() => {
                      setActiveView('graph');
                    }}
                  >
                    Charts
                  </Fab>
                ) : null}
                {_taskResults?.images?.length && _taskResults?.is_image ? (
                  <Fab
                    variant="extended"
                    size="small"
                    sx={{
                      boxShadow: activeView === 'image' ? 1 : 0,
                    }}
                    color={activeView === 'image' ? 'primary' : 'secondary'}
                    aria-label="add"
                    onClick={() => {
                      setActiveView('image');
                    }}
                  >
                    Images
                  </Fab>
                ) : null}
                {_taskResults?.is_grid && taskResultDatasets?.length ? (
                  <Fab
                    variant="extended"
                    size="small"
                    sx={{
                      boxShadow: activeView === 'dataset' ? 1 : 0,
                    }}
                    color={activeView === 'dataset' ? 'primary' : 'secondary'}
                    aria-label="add"
                    onClick={() => {
                      setActiveView('dataset');
                    }}
                  >
                    Datasets
                  </Fab>
                ) : null}
              </Fab>
            </Slide>
          ) : (
            ''
          )}

          {_taskResults && Object.keys(_taskResults).length !== 0 && (
            <Box width="100%" mt={5}>
              <Grid container spacing={3}>
                {_taskResults?.tables?.length && _taskResults?.is_table ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: activeView === 'table' ? 'block' : 'none',
                    }}
                  >
                    <Slide
                      direction="up"
                      in={activeView === 'table'}
                      timeout={900}
                    >
                      <Box>
                        {_taskResults?.tables.map((data, index) => (
                          <Box mb={4} key={data.id}>
                            <Zoom in={true}>
                              <Box>
                                <TableWithComment
                                  type="outputTable"
                                  comment={enableComments}
                                  tableData={data}
                                  opName={operationName || ''}
                                  commentid={_taskResults?.table_id[index]}
                                  // commenttableName={
                                  //   activeDataset?.Name
                                  //     ? activeDataset?.Name
                                  //     : ''
                                  // }
                                  isPaginated={data.is_paginated}
                                  customCell={customCell}
                                  permission={permission}
                                  fetchTableData={fetchTableDataResult}
                                  downloadTableData={downloadTableData}
                                  onAddTableClick={onAddTableClick}
                                  activeProject={activeProject}
                                  moduleName={moduleName}
                                  subModuleName={subModuleName}
                                  columnsToExcludeFromNumberFormatting={
                                    columnsToExcludeFromNumberFormatting
                                  }
                                  addResultToModelMetaData={
                                    addResultToModelMetaData
                                  }
                                  modelMetaDataSchema={modelMetaDataSchema}
                                />
                              </Box>
                            </Zoom>
                          </Box>
                        ))}
                      </Box>
                    </Slide>
                  </Grid>
                ) : null}
                {activeView === 'table' &&
                  (operationName === 'ModelInitiation' ||
                    operationName === 'ModelInitiationTS') && (
                    <Box width="100%" display="flex" justifyContent="center">
                      <Typography variant="body1">
                        {significanceMarks}
                      </Typography>
                    </Box>
                  )}
                {_taskResults?.charts?.length && _taskResults?.is_chart ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: activeView === 'graph' ? 'block' : 'none',
                    }}
                  >
                    <Slide
                      direction="up"
                      in={activeView === 'graph'}
                      timeout={900}
                    >
                      <Box my={0}>
                        <Grid container spacing={3}>
                          {_taskResults?.charts?.map((data) => (
                            <Grid
                              key={data?.id}
                              item
                              xs={12}
                              sm={_taskResults?.charts?.length > 1 ? 6 : 12}
                            >
                              <Zoom in={true}>
                                <Card
                                  className={
                                    _taskResults?.charts?.length > 1
                                      ? classes.graphCard
                                      : classes.singleGraphCard
                                  }
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    height="100%"
                                    pl={1}
                                    pr={1}
                                    pt={1}
                                    pb={1}
                                  >
                                    <Box display="flex" flexGrow={1}>
                                      <Box
                                        sx={{ width: '100%', height: '100%' }}
                                      >
                                        <IframeContainer
                                          codeSnippet={data.code}
                                          key={data.id}
                                          url={data.url}
                                          b64={data.b64}
                                          msg={data.msg}
                                          status={data.status}
                                          commentid={data.id}
                                          chartHeader={data?.chart_title}
                                          // commenttableName={activeDataset.Name}
                                          commentButtonStyle={{
                                            position: 'absolute',
                                            top: 17,
                                            right: 65,
                                          }}
                                          graphData={data}
                                          dialogOpen={dialogOpen}
                                          handleDialogOpen={handleDialogOpen}
                                          setGraphData={setGraphData}
                                          handleDialogClose={handleDialogClose}
                                          permission={permission}
                                          onAddChartClick={onAddChartClick}
                                          activeProject={activeProject}
                                          moduleName={moduleName}
                                          subModuleName={subModuleName}
                                          addResultToModelMetaData={
                                            addResultToModelMetaData
                                          }
                                          modelMetaDataSchema={
                                            modelMetaDataSchema
                                          }
                                          showCommentBox={enableComments}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Card>
                              </Zoom>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Slide>
                  </Grid>
                ) : null}

                {_taskResults?.images?.length && _taskResults?.is_image ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: activeView === 'image' ? 'block' : 'none',
                    }}
                  >
                    <Slide
                      direction="up"
                      in={activeView === 'image'}
                      timeout={900}
                    >
                      <Box my={0}>
                        <Grid container spacing={3}>
                          {_taskResults?.images?.map((data) => (
                            <Grid
                              key={`images_${data.id}`}
                              item
                              xs={12}
                              sm={_taskResults?.images?.length > 1 ? 6 : 12}
                            >
                              <Zoom in={true}>
                                <Card
                                  sx={(theme) => ({
                                    height:
                                      _taskResults?.images?.length > 1
                                        ? theme.spacing(60)
                                        : theme.spacing(80),
                                  })}
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    height="100%"
                                    pl={1}
                                    pr={1}
                                    pt={1}
                                    pb={1}
                                  >
                                    <Box display="flex" flexGrow={1}>
                                      <Box
                                        sx={{ width: '100%', height: '100%' }}
                                      >
                                        <OperationResultsViwerImageContainer
                                          url={data.url}
                                          showCommentBox={enableComments}
                                          commentId={data.id}
                                          b64={data.b64}
                                          // commentTableName={
                                          //   activeDataset?.Name
                                          //     ? activeDataset?.Name
                                          //     : ''
                                          // }
                                          imageHeader={data?.image_title}
                                          isConsole
                                          imgData={data}
                                          dialogOpen={dialogOpen}
                                          handleDialogOpen={handleDialogOpen}
                                          setImageData={setGraphData}
                                          handleDialogClose={handleDialogClose}
                                          permission={permission}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Card>
                              </Zoom>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Slide>
                  </Grid>
                ) : null}

                {taskResultDatasets?.length && _taskResults?.is_grid ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: activeView === 'dataset' ? 'block' : 'none',
                    }}
                  >
                    <Slide
                      direction="up"
                      in={activeView === 'dataset'}
                      timeout={900}
                    >
                      <Box>
                        <OperationResultsViewerDatasetTable
                          paginatedDatasetRowData={paginatedDatasetRowData}
                          totalDatasetRows={totalDatasetRows}
                          fetchDatasetRowsWithPagination={
                            fetchDatasetRowsWithPagination
                          }
                          datasetRowsPageCount={datasetRowsPageCount}
                          isLoading={isLoading}
                          projectid={activeProject?.project_id}
                          activeProject={activeProject}
                          datasetList={datasetList?.length ? datasetList : []}
                          permissions={permission}
                          taskResultDatasets={taskResultDatasets}
                          fetchAllDatasets={fetchAllDatasets}
                          markDatasetAsReadyToUse={markDatasetAsReadyToUse}
                          setSwitchReadyToUse={setSwitchReadyToUse}
                          enableComments={enableComments}
                        />
                      </Box>
                    </Slide>
                  </Grid>
                ) : null}
                <Dialog
                  fullWidth
                  aria-labelledby="customized-dialog-title"
                  maxWidth="xl"
                  TransitionComponent={DialogTransition}
                  open={dialogOpen}
                >
                  <Box height={650}>
                    <IframeContainer
                      codeSnippet={graphData.code}
                      key={0}
                      url={graphData.url}
                      msg={graphData.msg}
                      b64={graphData.b64}
                      status={graphData.status}
                      commentid={graphData.id}
                      // commenttableName={activeDataset.Name}
                      chartHeader={graphData?.chart_title}
                      graphData={graphData}
                      dialogOpen={dialogOpen}
                      handleDialogOpen={handleDialogOpen}
                      setGraphData={setGraphData}
                      handleDialogClose={handleDialogClose}
                      permission={permission}
                      moduleName={moduleName}
                      subModuleName={subModuleName}
                      addResultToModelMetaData={addResultToModelMetaData}
                      activeProject={activeProject}
                      modelMetaDataSchema={modelMetaDataSchema}
                      showCommentBox={enableComments}
                    />
                  </Box>
                </Dialog>
              </Grid>
            </Box>
          )}
        </>
      )}
    </StyledGrid>
  );
};
OperationResultsViewer.defaultProps = {
  activeProject: {},
  taskResultDatasets: [],
  paginatedDatasetRowData: [],
  totalDatasetRows: 0,
  fetchDatasetRowsWithPagination: () => {},
  datasetRowsPageCount: 0,
  isLoading: false,
  datasetList: [],
  columnsToExcludeFromNumberFormatting: [],
  fetchAllDatasets: () => {},
  markDatasetAsReadyToUse: () => {},
  moduleName: '',
  subModuleName: '',
  addResultToModelMetaData: null,
  fetchModelMetaDataSchema: null,
  enableComments: true,
};
OperationResultsViewer.propTypes = {
  taskResults: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // activeDataset: PropTypes.oneOfType([PropTypes.object]).isRequired,
  operationName: PropTypes.string,
  customCell: PropTypes.oneOfType([PropTypes.object]),
  permission: PropTypes.string.isRequired,
  parameters: PropTypes.oneOfType([PropTypes.object]),
  fetchTableDataResult: PropTypes.func,
  downloadTableData: PropTypes.func,
  onAddTableClick: PropTypes.func,
  onAddChartClick: PropTypes.func,
  activeProject: PropTypes.oneOfType([PropTypes.object]),
  taskResultDatasets: PropTypes.oneOfType([PropTypes.array]),
  paginatedDatasetRowData: PropTypes.oneOfType([PropTypes.array]),
  totalDatasetRows: PropTypes.number,
  fetchDatasetRowsWithPagination: PropTypes.func,
  datasetRowsPageCount: PropTypes.number,
  isLoading: PropTypes.bool,
  datasetList: PropTypes.oneOfType([PropTypes.array]),
  moduleName: PropTypes.string,
  subModuleName: PropTypes.string,
  columnsToExcludeFromNumberFormatting: PropTypes.oneOfType([PropTypes.array]),
  fetchAllDatasets: PropTypes.func,
  markDatasetAsReadyToUse: PropTypes.func,
  addResultToModelMetaData: PropTypes.func,
  fetchModelMetaDataSchema: PropTypes.func,
  enableComments: PropTypes.bool,
};
export default React.memo(OperationResultsViewer);
